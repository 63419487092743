
export default {
    data() {
        return {
            showSubmenu: false,
        };
    },
    methods: {
        close() {
            this.showSubmenu = false
        },
    }
}