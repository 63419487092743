<template>
  <div :id="id" class="" @click.stop="">
    <div class="list-product-tab-label" v-show="mustHave">
      <div class="list-product-tab-label__ico">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.4389 3.07335C12.7311 2.36536 11.7927 1.9349 10.7944 1.86027C9.79603 1.78564 8.80407 2.07179 7.99888 2.66668C7.15065 2.03578 6.09488 1.74969 5.04416 1.86604C3.99345 1.98239 3.02584 2.49254 2.3362 3.29374C1.64655 4.09495 1.2861 5.1277 1.32742 6.18403C1.36873 7.24036 1.80876 8.2418 2.55888 8.98668L7.52555 13.9534C7.58752 14.0158 7.66126 14.0654 7.7425 14.0993C7.82374 14.1331 7.91087 14.1505 7.99888 14.1505C8.08689 14.1505 8.17403 14.1331 8.25527 14.0993C8.33651 14.0654 8.41024 14.0158 8.47221 13.9534L13.4389 8.98668C13.8273 8.5985 14.1355 8.13758 14.3457 7.63026C14.5559 7.12294 14.6641 6.57917 14.6641 6.03002C14.6641 5.48086 14.5559 4.93709 14.3457 4.42977C14.1355 3.92246 13.8273 3.46154 13.4389 3.07335ZM12.4989 8.04668L7.99888 12.54L3.49888 8.04668C3.10233 7.64848 2.8322 7.14205 2.72237 6.59091C2.61254 6.03977 2.6679 5.46847 2.88152 4.94868C3.09513 4.42889 3.45748 3.98375 3.92311 3.6691C4.38874 3.35445 4.93692 3.18431 5.49888 3.18002C6.24962 3.18186 6.96895 3.48158 7.49888 4.01335C7.56086 4.07584 7.63459 4.12543 7.71583 4.15928C7.79707 4.19313 7.88421 4.21055 7.97221 4.21055C8.06022 4.21055 8.14736 4.19313 8.2286 4.15928C8.30984 4.12543 8.38357 4.07584 8.44555 4.01335C8.9911 3.54061 9.69588 3.29278 10.4172 3.32003C11.1386 3.34729 11.8227 3.64758 12.331 4.16014C12.8393 4.6727 13.1339 5.35924 13.1552 6.08081C13.1765 6.80238 12.9228 7.50508 12.4455 8.04668H12.4989Z"
            fill="#5D50C6"
          />
        </svg>
      </div>
      {{ $t("generic.mustHave") }}
    </div>
    <article class="list-product list-product--static" @click="previewObject">
      <div class="list-ellipsis-submenu" @click.stop="showSubmenu = !showSubmenu">
        <div class="list-ellipsis-submenu__label" v-click-outside="config">
          <img src="../../../assets/img/ico/ico-ellipsis.svg" alt="" />
        </div>
        <div class="list-ellipsis-submenu__items" v-if="showSubmenu">
          <a @click.prevent.stop="editObject">{{ $t("generic.edit") }}</a>
          <delete-product
            :product="product"
            @deleted="deleted"
            @click="showSubmenu = false"
            :listId="list.id"
          ></delete-product>
        </div>
      </div>

      <div class="list-product__image">
        <img :loading="index > 1 ? 'lazy' : 'auto'" :src="$getImgUrl(product.photo)" />
      </div>
      <div class="list-product__right">
        <h2 class="list-product__title list-product__title--constructor">
          {{ product.name }}

          <span
            v-if="
              product.selectedVariantText &&
              product.selectedVariantText != 'Title: Default Title'
            "
            class="list-product__variables"
          >
            {{ product.selectedVariantText }}
          </span>
        </h2>

        <div class="list-product__shops">
          <div class="list-product-shops">
            <div
              class="list-product-shops__item"
              v-for="(retailer, index) in productRetailers"
              :key="index"
            >
              <div class="list-product-shops__shop">
                <img
                  :loading="index > 1 ? 'lazy' : 'auto'"
                  :src="$getLogoRetalier(retailer.name, retailer, product)"
                  alt
                />
                <span v-if="product.isFree">{{ product.retailerName }}</span>
                <span v-else>{{ retailer.name }}</span>
              </div>
              <div class="list-product-shops__price">
                {{
                  product.isFree
                    ? $utils.formatMoney(retailer.price, currency)
                    : $utils.formatMoney(retailer.price)
                }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="showMoreShops" class="list-product__actions">
          <span
            @click.stop.prevent="toggleMoreShops = !toggleMoreShops"
            class="list-product-shops__more"
            ><span v-text="moreShopsSimbol"></span> {{ $t("mylist.stores") }}</span
          >
        </div>
      </div>

      <aside class="list-product__status">
        <div class="list-product__label" v-if="product.status != 1">
          <span class="badge" :class="[statusColor]">{{
            statusText[product.status]
          }}</span>
        </div>

        <label for="regalado" @click.prevent.stop="openMarkAsGiftedModal()" v-else>
          <input type="checkbox" id="regalado" :checked="isGifted" />
          <span>{{ $t("generic.setAsPurchased") }}</span>
        </label>
      </aside>

      <aside
        class="list-product__progress"
        :class="product.status == 1 ? 'list-product__progress--status-1' : ''"
        v-show="
          contributionsFlag &&
          contributionsEnabled &&
          contributionsEnabledForProductPrice(product.price, list) &&
          product.retailerName != 'HelloBB' &&
          product.status == 1
        "
      >
        <div class="list-product-success" v-if="contributionCompleted">
          <span class="badge badge--success badge--medium"
            >{{ $t("mylist.contributionsCompleted") }}</span
          >
          <a class="link link--underline link--bold" @click.stop="goToProductPage()"
            >{{ $t("generic.buy") }}</a
          >
        </div>
        <div class="list-product-progress" v-else>
          <span class="list-product-progress__label">
            {{ $t("mylist.receivedContributions") }}:
            <strong>{{ $utils.formatMoney(product.contributionAmount) }}</strong>
          </span>
          <div class="list-product-progress__bar">
            <ProgressBar :now="product.contributionProgression"></ProgressBar>
          </div>
        </div>
      </aside>
    </article>

    <modal-dialog
      ref="modalGift"
      :show="showModalGift"
      @close="closeMarkAsGiftedModal()"
      target-class="modal__card--mini"
    >
      <div class="card-wizard">
        <div class="card-wizard__title">
          <h3 class="header-3 w-70">
            {{ $t("mylist.msgTitleConfirmSetAsPurchased") }}
          </h3>
        </div>
        <p
          v-show="
          contributionsFlag &&
          contributionsEnabled &&
          contributionsEnabledForProductPrice(product.price, list) &&
          product.retailerName != 'HelloBB' &&
          !contributionCompleted
        ">{{ $t("mylist.msgConfirmAsPurchasedWithContributions") }}</p>
        <div class="card-wizard__actions card-wizard__actions--mt-0">
          <loading-button v-if="markAsGiftIsLoading"></loading-button>
          <button
            v-else
            class="button button--primary button--block"
            @click="markAsGifted()"
          >
            {{ $t("mylist.msgButtonSetAsPurchased") }}
          </button>
          <button
            class="button button--secondary mar-t-fs-smaller button--block"
            @click="$refs.modalGift.close()"
          >
            {{ $t("generic.cancel") }}
          </button>
        </div>
      </div>
    </modal-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import CloseOnClickOutside from "../../../components/Mixins/CloseOnClickOutside";
import CanBeClosedByEscKey from "../../../Mixins/CanBeClosedByEscKey";
import HasSubMenu from "./Mixins/HasSubMenu";

import ProgressBar from "../../../components/ui/progress/ProgressBar.vue";
import ContributionsEnabledForProduct from "../../../Mixins/ContributionsEnabledForProduct";
import ModalDialog from "../../../components/ui/ModalDialog";
import LoadingButton from "../../../components/LoadingButton";

export default {
  name: "ListProductCard",
  components: {
    ProgressBar,
    DeleteProduct: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "DeleteProduct" */ "./DeleteProduct"
      ),
    ModalDialog,
    LoadingButton,
  },
  mixins: [
    CloseOnClickOutside,
    CanBeClosedByEscKey,
    HasSubMenu,
    ContributionsEnabledForProduct,
  ],
  props: {
    productData: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    contributionsEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      toggleMoreShops: false,
      showModalGift: false,
      markAsGiftIsLoading: false,
      overrideProduct: null,
    };
  },
  computed: {
    ...mapGetters({
      list: "ownerList",
      contributionsFlag: "contributionsFlag",
      currency: "loggedInUserCurrency",
    }),
    product() {
      return this.overrideProduct || this.productData;
    },
    id() {
      return this.product.isFree
        ? "free_" + this.product.id
        : "catalogue_" + this.product.id;
    },
    productRetailers() {
      if (this.product.isFree) {
        return [
          {
            name: this.retailerName,
            price: this.product.price,
          },
        ];
      }
      return (
        this.product.productRetailers?.slice(
          0,
          this.toggleMoreShops ? this.product.productRetailers?.length : 2
        ) || []
      );
    },
    showMoreShops() {
      return this.product.productRetailers?.length > 2;
    },
    moreShopsSimbol() {
      return this.toggleMoreShops ? "-" : "+";
    },
    statusColor() {
      return this.color[this.product.status || 1];
    },
    mustHave() {
      return this.product.mustHave;
    },
    retailerName() {
      return this.$utils.capitalize(this.retailerNames[this.product.retailerName]);
    },
    contributionCompleted() {
      return this.product.contributionProgression >= 100;
    },
    isGifted() {
      this.product.status === this.REGALADO;
    },
  },
  methods: {
    deleted() {
      this.showSubmenu = false;
      if (this.$clientSide()) document.body.classList.remove("modal-open");
      this.$emit("deleted");
    },
    previewObject() {
      if (!this.$utils.isApp()) {
        this.$router.push({
          name: "owner-list-shop-product",
          params: {
            productType: this.product.isFree ? "free" : "catalog",
            id: this.product.isFree ? this.product.id : this.product.listProductId,
          },
        });
      } else {
        //https://hellobb.net/mi-lista/product/catalog/38857
        this.$router.push({
          name: "owner-list-shop-product",
          params: {
            productType: this.product.isFree ? "free" : "catalog",
            id: this.product.isFree ? this.product.id : this.product.listProductId,
            replace: true,
          },
        });
      }
    },
    editObject() {
      if (!this.$utils.isApp()) {
        this.$router.push({
          name: "owner-list-edit-product",
          params: {
            productType: this.product.isFree ? "free" : "catalog",
            id: this.product.isFree ? this.product.id : this.product.listProductId,
          },
        });
      } else {
        this.$router.push({
          name: "owner-list-edit-product",
          params: {
            productType: this.product.isFree ? "free" : "catalog",
            id: this.product.isFree ? this.product.id : this.product.listProductId,
            replace: true,
          },
        });
      }
    },
    goToProductPage() {
      if (this.product.isFree) {
        window.open(this.product.url, "_blank");
      } else {
        var _name = "product";
        var _url = this.brandUrl();
        if (this.product.metatitle) {
          _name = "product-new";
          _url = this.nameUrl();
        }
        let to = {
          name: _name,
          params: { name: _url, brand: _url, id: this.product.id },
        };
        this.$router.push({
          name: to.name,
          params: to.params,
        });
      }
    },
    brandUrl() {
      if (this.product.url) {
        return this.$formatUrlhelloBB(this.product.url);
      } else {
        return this.$formatUrlhelloBB(this.product.brand);
      }
    },
    nameUrl() {
      return this.$formatUrlhelloBB(this.product.metaInfo?.title);
    },
    openMarkAsGiftedModal() {
      this.showModalGift = true;
    },
    closeMarkAsGiftedModal() {
      this.showModalGift = false;
    },
    async markAsGifted() {
      this.markAsGiftIsLoading = true;

      try {
        const list = this.list;
        const product = this.product;
        const status = 2;

        if (product.isFree) {
          await this.$store.dispatch("updateFreeProductStatus", {
            list,
            product,
            status,
          });

          this.overrideProduct = await this.$store.dispatch("getOwnerListFreeProduct", {
            productId: product.id,
          });
        } else {
          await this.$store.dispatch("updateProductStatus", {
            product,
            status,
          });

          this.overrideProduct = await this.$store.dispatch("getListProduct", {
            productId: product.listProductId,
            listId: list.id,
          });
        }
      } finally {
        this.closeMarkAsGiftedModal();
        this.markAsGiftIsLoading = false;
      }
    },
  },
  created() {
    this.retailerNames = {
      amazon: "Amazon",
      Amazon: "Amazon",
      elcorteingles: "El Corte Inglés",
      Elcorteingles: "El Corte Inglés",
      bebitus: "Bebitus",
      Bebitus: "Bebitus",
      ikea: "Ikea",
      Ikea: "Ikea",
    };
    this.PENDIENTE = 1;
    this.REGALADO = 2;
    this.RESERVADO = 3;
    this.statusText = {
      [this.PENDIENTE]: this.$t("generic.available"),
      [this.REGALADO]: this.$t("generic.purchased"),
      [this.RESERVADO]: this.$t("generic.reserved"),
    };
    this.color = {
      [this.PENDIENTE]: "",
      [this.REGALADO]: "badge--success",
      [this.RESERVADO]: "badge--accent",
    };
  },
};
</script>
