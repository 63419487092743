<template>
  <div class="bb-progress">
    <div
      class="bb-progress__bar"
      role="progressbar"
      :style="{ width: now + '%' }"
      :aria-valuenow="now"
      :aria-valuemin="min"
      :aria-valuemax="max"
    ></div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    now: {
      type: Number,
      default: 50,
    },
  },
};
</script>
