import { mapGetters } from "vuex";

import { ENABLED_IN } from "../constants/contributions-configuration";

export default {
  computed: {
    ...mapGetters({
      contributionsConfigurationEnabledIn: "contributionsConfigurationEnabledIn",
      contributionsConfigurationActivationThresholdAmount: "contributionsConfigurationActivationThresholdAmount",
    }),
  },
  methods: {
    contributionsEnabledForProductPrice(price, list = null) {
      if (list && !list.stripeAccountId) {
          return false;
      }

      if (this.contributionsConfigurationEnabledIn !== ENABLED_IN.all) {
          return false;
      }

      if (price < this.contributionsConfigurationActivationThresholdAmount) {
          return false;
      }

      return true;
    }
  },
}
